<template>
    <div id="User_Profile" class="container-fluid p-0 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-12 pt-3">
                <button @click.stop="$router.back()"
                    class="btn btn-outline-primary btn-pill">
                    <img :src="arrow_left_icon" alt="" />
                    Regresar
                </button>
            </div>

            <div class="col-12 p-0 m-0 mt-4">
                <div class="row p-0 m-0">

                    <div class="col-6 p-0  m-0 d-flex align-items-center">
                        <div class="row">

                            <div class="col-12">
                                <div class="row">
                                     
                                    <div class="col m-0">
                                        <b-icon 
                                            icon="person-circle"
                                            variant="white"
                                            scale="1"
                                            />
                                    </div>
                                    <div class="col-11 p-0">
                                        <p style="font-size: 16px; margin: 0;">
                                            Nombre de usuario:
                                        </p>
                                        <span style="font-size: 20px;">
                                            Juan Pérez
                                        </span>
                                    </div>
                                </div>
                                
                                
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>


                            <div class="col-12 pt-2">
                                
                                <div class="row">
                                    <div class="col m-0">
                                        <b-icon 
                                            icon="telephone"
                                            variant="white"
                                            />
                                    </div>
                                    <div class="col-11 p-0">
                                        <p style="font-size: 16px; margin: 0;">
                                            Teléfono:
                                        </p>
                                        <span style="font-size: 20px;">
                                            (33)-56-78-67-90
                                        </span>
                                    </div>
                                </div>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 pt-2">
                                <div class="row">
                                    <div class="col m-0">
                                        <b-icon 
                                            icon="person-badge"
                                            variant="white"
                                            scale=""
                                            />
                                    </div>
                                    <div class="col-11 p-0">
                                        <p style="font-size: 16px; margin: 0;">
                                            Rol de usuario:
                                        </p>
                                         <span style="font-size: 20px;">
                                            Administrador
                                        </span>
                                    </div>
                                </div>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                        </div>
                    </div>

                    <div class="col-6  p-0 m-0">
                        <div style="max-width: 400px;">
                            <img :src="placeholder_image" 
                                class="img-fluid" 
                                style="border-radius: 15px;" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    //icons
    import arrow_left_icon from 'ASSETS/icons/arrow_left.png'
    import placeholder_image from 'ASSETS/images/mountains-example.jpg'

    export default {
        data(){
            return {
                arrow_left_icon,
                placeholder_image
            };
        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>

</style>
